import React from 'react';
import PropTypes from 'prop-types';


const ExternalLink = ({ to, label, children }) => {
  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={label}
    >
      {children}
    </a>
  );
};


ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
};


ExternalLink.defaultProps = {
  label: null,
};


export default ExternalLink;
